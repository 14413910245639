<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>
                  <v-stepper-step step="" class="active-step today-bold">
                    Póliza
                  </v-stepper-step>

                  <v-divider inset class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-bold">
                    Simulador
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-bold">
                    Formulario
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-bold">
                    Firma de documentos
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-bold">
                    Pago
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>
            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Firma pagaré
              </div>
            </v-col>

          </v-row>

          <v-layout justify-center pt-5>
            <v-card-actions>
              <v-btn
                  :loading="loadingSend"
                  :disabled="loadingSend"
                  @click="sendSignaturePayment"
                  color="primary"
                  class="px-10 text-capitalize vardi-button"
                  elevation="0">
                Continuar
              </v-btn>
            </v-card-actions>
          </v-layout>

        </v-container>
      </v-col>
    </v-row>

    <v-dialog
        v-model="signPaymentDialog"
        max-width="400"
        overlay-color="primary" >

      <v-card class="pa-4 text-center">

        <v-img max-height="50"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/vardi/cheque.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center primary--text">FIRMAR PAGARÉ</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-4">
          Una vez ingreses el código de validación firmarás electrónicamente el pagaré de tú solicitud de crédito.
        </h3>

        <div class="px-4 mt-2">
          <v-form
              ref="form"
              lazy-validation>
            <v-row>
              <v-col cols="2" class="px-1 px-md-2 text-center">
                <v-text-field
                    ref="digit1"
                    class="px-0"
                    type="number"
                    required
                    outlined
                    dense
                    color="primary"
                    v-on:keyup="goNext"></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1 px-md-2 text-center">
                <v-text-field
                    ref="digit2"
                    class="px-0"
                    type="number"
                    required
                    outlined
                    dense
                    color="primary"
                    v-on:keyup="goNext"></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1 px-md-2 text-center">
                <v-text-field
                    ref="digit3"
                    class="px-0"
                    type="number"
                    required
                    outlined
                    dense
                    color="primary"
                    v-on:keyup="goNext"></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1 px-md-2 text-center">
                <v-text-field
                    class="px-0"
                    type="number"
                    required
                    outlined
                    dense
                    color="primary"></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1 px-md-2 text-center">
                <v-text-field
                    class="px-0"
                    type="number"
                    required
                    outlined
                    dense
                    color="primary"></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1 px-md-2 text-center">
                <v-text-field
                    class="px-0"
                    type="number"
                    required
                    outlined
                    dense
                    color="primary"></v-text-field>
              </v-col>
            </v-row>

            <p class="primary--text today-medium" style="font-size: 0.7em;">Se habilitará un nuevo código en 00:{{ countdown }}</p>

          </v-form>
        </div>

        <v-card-actions class="justify-center">
          <v-btn
              :loading="loadingCellphone"
              :disabled="loadingCellphone"
              color="primary"
              class="px-10 text-capitalize vardi-button"
              @click="goPayment"
              elevation="0"
          >
            Continuar
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";

export default {
  name: 'SignaturePayment',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      signPaymentDialog: false,
      loadingSend: false,
      codeSent: false,
      expires_in: 60,
      countdown: '',
    }
  },
  mounted() {
  },
  methods: {
    goPayment() {
      this.$router.push('/pago');
    },
    sendSignaturePayment() {
      this.loadingSend = true;
      this.startCountDown();

      setTimeout(function () {
        this.signPaymentDialog = true;
        this.loadingSend = false;
      }.bind(this), 500)

    },

    calculateFee() {
      this.valueFinance = parseInt(this.valueFinance, 10);

      let _rate = this.rate / 100;
      this.feeValue = _rate / (1 - Math.pow(1 + _rate, -this.feeNumber));
      this.feeValue = this.feeValue * this.valueFinance;

      this.amortizedFee = this.feeValue;
      this.firstFeeValue = this.amortizedFee + this.creditStudy + this.insurance + this.ivaValue;
      this.othersFeeValue = this.amortizedFee + this.admin;

    },
    formatCurrency: function(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,

      });
      return formatter.format(parseInt(value, 10));
    },
    startCountDown: function() {
      this.interval = setInterval(() => {
        if (this.expires_in === 0) {
          //clearInterval(this.interval);
          this.expires_in = 60;
        } else {
          this.expires_in -= 1;
          this.countdown = this.expires_in;
        }
      }, 1000);
    },
    goNext(event){
      console.log(this.$refs.digit2)
      this.$refs.digit2.focus()
    }
  },
  created: function() {
    this.calculateFee();
  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 12px;
}

.active-step .v-stepper__label {
  color: #00205B !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #00205B !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #00205B !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 450px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}

</style>